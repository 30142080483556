import React, { useState, useEffect, useCallback, Suspense, lazy } from 'react';
import './App.css';
import Loading from './Loading';
import { Helmet } from 'react-helmet-async';

const Menu = lazy(() => import('./Menu'));
const GalleryItem = lazy(() => import('./GalleryItem'));
const MediaOverlay = lazy(() => import('./MediaOverlay'));

function importAll(r) {
  return r.keys().map(r);
}

const images = importAll(require.context('../public/images', false, /\.webp$/));
const audios = importAll(require.context('../public/audio', false, /\.mp3$/));

const createMediaItems = (start, length) => {
  return Array.from({ length }).map((_, index) => {
    const imageIndex = (start + index) % images.length;
    const imageName = images[imageIndex].split('/').pop().split('.')[0];
    const audio = audios.find(audio => audio.includes(imageName));
    return {
      id: start + index,
      src: images[imageIndex],
      title: imageName,
      mediaUrl: audio,
      sizeClass: Math.random() < 0.2 ? 'large' : 'medium'
    };
  });
};

function App() {
  const [items, setItems] = useState([]);
  const [activeMedia, setActiveMedia] = useState(null);
  const [hiddenItem, setHiddenItem] = useState(null);
  const [mediaVisible, setMediaVisible] = useState(false);
  const [fadingItem, setFadingItem] = useState(null);
  const [initialPosition, setInitialPosition] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const loadMoreItems = useCallback(() => {
    setItems((prevItems) => {
      const nextItems = createMediaItems(prevItems.length, 20);
      return [...prevItems, ...nextItems];
    });
  }, []);

  const handleClick = (rect, item) => {
    setInitialPosition(rect);
    setFadingItem(item.id);

    setTimeout(() => {
      setHiddenItem(item.id);
      setActiveMedia({ mediaUrl: item.mediaUrl, thumbnailSrc: item.src });

      setTimeout(() => {
        setMediaVisible(true);
      }, 500); // Delay for overlay to fade in
    }, 500); // Delay for item to fade out
  };

  const handleCloseOverlay = () => {
    setMediaVisible(false);

    setTimeout(() => {
      setHiddenItem(null);
      setActiveMedia(null);
      setFadingItem(null);
      setInitialPosition(null);
    }, 500); // Delay for overlay to fade out
  };

  useEffect(() => {
    loadMoreItems(); // Load initial items
    setIsLoading(false); // Simulate loading time
  }, [loadMoreItems]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 100) {
        loadMoreItems();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loadMoreItems]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Suspense fallback={<Loading />}>
      <Helmet>
        <title>LBG Legacy - Rock Band from Chennai, India</title>
        <meta name="description" content="LBG Legacy is a rock band from Chennai, India. We have released three albums and multiple singles. Explore our music and get to know more about us." />
        <meta name="keywords" content="LBG Legacy, Chennai, Rock Band, Indian Rock, Music, Albums, Singles" />
        <meta name="author" content="LBG Legacy" />
        <meta property="og:title" content="LBG Legacy - Rock Band from Chennai, India" />
        <meta property="og:description" content="LBG Legacy is a rock band from Chennai, India. We have released three albums and multiple singles. Explore our music and get to know more about us." />
        <meta property="og:image" content="URL_TO_IMAGE" />
        <meta property="og:url" content="YOUR_WEBSITE_URL" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="LBG Legacy" />
        <meta property="og:locale" content="en_IN" />
        <meta name="instagram:site" content="@lbglegacy" />
        <meta name="youtube:channel" content="https://www.youtube.com/channel/UC9NRoJAjp-PhceswMyv6f9g" />
      </Helmet>
      <div id="outer-gallery-container">
        <Menu />
        <div id="gallery">
          {items.map((item) => (
            <GalleryItem
              key={item.id}
              src={item.src}
              title={item.title}
              sizeClass={item.sizeClass}
              isHidden={hiddenItem === item.id}
              isVisible={fadingItem !== item.id}
              onClick={(rect) => handleClick(rect, item)}
            />
          ))}
        </div>
        {activeMedia && (
          <MediaOverlay
            mediaUrl={activeMedia.mediaUrl}
            thumbnailSrc={activeMedia.thumbnailSrc}
            isVisible={mediaVisible}
            initialPosition={initialPosition}
            onClose={handleCloseOverlay}
          />
        )}
      </div>
    </Suspense>
  );
}

export default App;
